import { Link } from "react-router-dom";
import classNames from "classnames";

import Twitter from "./img/Twitter";
import LinkedIn from "./img/LinkedIn";
import Instagram from "./img/Instagram";

/*
{props.hasShadow && (
      <div className="footer__menu-shadow-wrapper">
        <FooterMenu
          {...props}
          color={"#000"}
          className="footer__menu footer__menu--shadow"
        />
      </div>
    )}
 */

export const legalMenu = (legal) =>
  legal?.map((item) => ({
    slug: item.slug,
    linkText: item.title.rendered,
    lineColor: "red",
    href: `#${item.slug}`,
  }));

const linkClass = ({ activeItem, lineColor, slug }) => {
  return classNames({
    footer__link: true,
    [`footer__link--${lineColor}`]: true,
    "footer__link--active": activeItem === slug,
  });
};

const FooterMenu = ({
  activeItem,
  className,
  legal,
  settings: { linkedInUrl, twitterUrl, instagramUrl } = {},

  /// Added by Andi - 22/11/2019 - To Show and hide the Help Widget
  activateHelper = function (e, href) {
    ///e.preventDefault();
    var body = document.getElementsByTagName("body")[0];

    if (href === "#customer-support") {
      body.setAttribute("class", "show-jsd-widget");
    } else {
      body.removeAttribute("class", "show-jsd-widget");
    }
  },
}) => (
  <div className={className}>
    <Link
      to="/about"
      data-link-text="About"
      className={linkClass({
        lineColor: "red",
        linkText: "About",
        slug: "about",
      })}
    >
      About
    </Link>
    {legalMenu(legal)?.map(({ slug, linkText, lineColor }) => (
      <a
        key={`footer-link--${slug}`}
        href={`#${slug}`}
        data-link-text={linkText}
        className={linkClass({
          slug,
          lineColor,
          activeItem,
        })}
        onClick={(e) => {
          activateHelper(e, `#${slug}`);
        }}
      >
        {linkText}
      </a>
    ))}
    <SocialLink
      url={linkedInUrl || "https://uk.linkedin.com"}
      title="LinkedIn"
      Icon={LinkedIn}
    />
    <SocialLink
      url={twitterUrl || "https://twitter.com"}
      title="Twitter"
      Icon={Twitter}
    />
    <SocialLink
      url={instagramUrl || "https://instagram.com"}
      title="Instagram"
      Icon={Instagram}
    />
  </div>
);

const SocialLink = ({ url, title, Icon }) => (
  <a
    className="footer__link footer__link--social"
    href={url}
    target="_blank"
    rel="noopener noreferrer"
  >
    <span className="sr-only">{title}</span>
    <Icon color={"currentColor"} />
  </a>
);

const Footer = (props) => (
  <div
    className={classNames("footer", {
      "footer--sticky": props.sticky,
      "footer--light-text": props.lightText,
    })}
  >
    <FooterMenu {...props} color={"#fff"} className="footer__menu" />
  </div>
);

export default Footer;
