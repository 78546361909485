import { useEffect, useState } from "react";
import { contactService } from "../../services/contact.service";
import { toContactPage } from "../../helpers/parse-data";

export interface Contact {
  id: string;
  date: string;
  title: string;
  shortTitle: string;
  content: string;
  slug: string;
  type: string;
  youtube_video: string;
  image: {
    url: string;
    sizes: {
      [key: string]: string;
    };
  };
}

export interface UseContact {
  contact: Contact | undefined;
  loading: boolean;
  error: any;
}

const useContact = (): UseContact => {
  const [contact, setContact] = useState<Contact>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    const getContact = async () => {
      setLoading(true);
      setError(false);

      try {
        const response = await contactService.getContact();
        if (response) setContact(toContactPage(response));
      } catch (error) {
        setError(error);
      }
      setLoading(false);
    };

    getContact();
  }, []);

  return { contact, loading, error };
};

export default useContact;
