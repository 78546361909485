import { Component } from "react";
import {
  Route,
  Switch,
  BrowserRouter as Router,
  Redirect,
} from "react-router-dom";
import { BASE_URL } from "../constants/resources.constants";

import ErrorBoundary from "../components/ErrorBoundary";
import Logo from "../assets/icons/images/LogoBlack.svg";

import NewsPage from "../components/pages/NewsPage";
import CaseStudiesPage from "../components/pages/CaseStudiesPage";
import ProductsPage from "../components/pages/ProductsPage";
import SolutionsPage from "../components/pages/SolutionsPage";
import BlogsPage from "../components/pages/BlogsPage";
import AboutPage from "../components/pages/AboutPage";
import ProductPage from "../components/pages/ProductPage";
import SolutionPage from "../components/pages/SolutionPage";
import CaseStudiesSinglePage from "../components/pages/caseStudiesSinglePage";

import Error404 from "../templates/Error404";

import { buildMenu } from "../helpers/parse-data";
import ContactPage from "../components/pages/ContactPage";
import HomePage from "../components/pages/HomePage";
import BlogsSinglePage from "../components/pages/BlogsSinglePage";
import NewsSinglePage from "../components/pages/NewsSinglePage";

import "../assets/icons";

import "../styles";

const get =
  (parse = (x) => x) =>
  (url) =>
    fetch(`${BASE_URL}/${url}`)
      .then((res) => {
        if (!res.ok) {
          throw res;
        }

        return res;
      })
      .then((res) => res.json())
      .then(parse);

class App extends Component {
  state = {
    home: null,
    productsPage: null,
    products: null,
    loaded: false,
    failed: false,
  };

  async componentDidMount() {
    await this.fetchSiteData();
  }

  async fetchSiteData() {
    const apiMapping = {
      productsMenu: {
        url: "wp-json/menus/v1/menus/product-menu",
        parse: (res) => buildMenu(res.items),
      },
      solutionsMenu: {
        url: "wp-json/menus/v1/menus/application-menu",
        parse: (res) => buildMenu(res.items),
      },
      legal: {
        url: "wp-json/wp/v2/legal",
      },
      settings: {
        url: "wp-json/acf/v3/options/sbg-settings",
        parse: (res) => res.acf,
      },
    };
    const keys = Object.keys(apiMapping);

    try {
      const res = await Promise.all(
        keys.map((key) => get(apiMapping[key].parse)(apiMapping[key].url))
      );

      const apiRes = res.reduce((accum, r, i) => {
        accum[keys[i]] = r;

        return accum;
      }, {});

      /* Only update state if content has changed */
      const contentLength = JSON.stringify(apiRes).length;

      if (contentLength !== this.state.contentLength) {
        this.setState(apiRes);
        this.setState({ contentLength });
        this.setState({ loaded: true });
      }
    } catch (e) {
      this.setState({ failed: true });
      console.error(e);
    }
  }

  render() {
    const { failed } = this.state;

    if (failed) {
      return (
        <div className="api-failure">
          <div className="api-failure__logo">
          <img src={Logo} alt={"SBG a Catapult Company"}/>
          </div>
          <div className="api-failure__message">Resource Not Found</div>
        </div>
      );
    }

    return (
      <ErrorBoundary>
        <Router>
          <div className="app">
            <Switch>
              <Route
                exact
                path="/"
                component={() => <HomePage {...this.state} />}
              />
              <Route
                exact
                path="/products"
                component={() => <ProductsPage {...this.state} />}
              />
              <Route
                exact
                path="/product/:articleTitle/:sectionId?"
                component={(props) => (
                  <ProductPage {...this.state} {...props} />
                )}
              />
              <Route
                exact
                path="/solutions"
                component={() => <SolutionsPage {...this.state} />}
              />
              <Route
                exact
                path="/solution/:articleTitle/:sectionId?"
                component={(props) => (
                  <SolutionPage {...this.state} {...props} />
                )}
              />
              <Route
                exact
                path="/about"
                component={(props) => <AboutPage {...this.state} {...props} />}
              />
              <Route
                exact
                path="/contact"
                component={(props) => (
                  <ContactPage {...this.state} {...props} />
                )}
              />
              <Route
                exact
                path="/news"
                component={(props) => <NewsPage {...this.state} {...props} />}
              />
              <Route
                exact
                path="/news/:slug"
                component={(props) => (
                  <NewsSinglePage {...this.state} {...props} />
                )}
              />
              <Route
                exact
                path="/casestudies"
                component={(props) => (
                  <CaseStudiesPage {...this.state} {...props} />
                )}
              />
              <Route
                exact
                path="/casestudies/:slug"
                component={(props) => (
                  <CaseStudiesSinglePage {...this.state} {...props} />
                )}
              />
              <Route
                exact
                path="/blog"
                component={(props) => <BlogsPage {...this.state} {...props} />}
              />
              <Route
                exact
                path="/blog/:slug"
                component={(props) => (
                  <BlogsSinglePage {...this.state} {...props} />
                )}
              />
              <Route
                exact
                path="/legal/:slug"
                component={(props) => {
                  return <Redirect to={`/#${props.match.params.slug}`} />;
                }}
              />
              <Route
                exact
                path="/mpa"
                component={() => <Redirect to="/#master-purchase-agreement" />}
              />
              <Route component={() => <Error404 {...this.state} />} />
            </Switch>
          </div>
        </Router>
      </ErrorBoundary>
    );
  }
}

export default App;
