const DownArrow = () => (
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 21 21"
    width="15"
    height="15"
  >
    <path
      fill="currentColor"
      d="M4.8 6.1l5.7 5.7 5.7-5.7 1.6 1.6-7.3 7.2-7.3-7.2 1.6-1.6z"
    />
  </svg>
);

export default DownArrow;
