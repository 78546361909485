import { FC } from "react";
import LongForm from "../../templates/LongForm";
import useProduct from "../../hooks/product/useProduct";
import SiteLoader from "../common/siteLoader";
import withLayout from "../../templates/withLayout";

export interface ProductPageProps {}

const ProductPage: FC<ProductPageProps> = ({ ...props }) => {
  const { product } = useProduct();

  if (!product) return <SiteLoader />;

  return <LongForm data={product} {...props} />;
};

export default withLayout(ProductPage, {
  template: "long-form",
  footer: { sticky: true, lightText: true },
  header: { hasGradient: false },
});
