import { Link } from "react-router-dom";

import classNames from "classnames";

import DropdownMenu from "./DropdownMenu";

const headerMenu = [
  {
    slug: "/products",
    linkText: "Products",
    lineColor: "green",
    menu: "productsMenu",
  },
  {
    slug: "/solutions",
    linkText: "Solutions",
    lineColor: "green",
    menu: "solutionsMenu",
  },
  {
    slug: "/casestudies",
    linkText: "Case Studies",
    lineColor: "green",
  },
  {
    slug: "/news",
    linkText: "News",
    lineColor: "green",
  },
  {
    slug: "/blog",
    linkText: "Blog",
    lineColor: "green",
  },
  {
    slug: "/contact",
    linkText: "Contact",
    lineColor: "green",
  },
];

const linkClass = ({ activeItem, lineColor, slug }) => {
  slug = slug.replace("/", "", slug);

  return classNames({
    header__link: true,
    [`header__link--${lineColor}`]: true,
    [`header__link--${slug}`]: true,
    "header__link--active": activeItem === slug,
  });
};

const HeaderMenu = ({ className, activeItem, disabled, menus }) => {
  return (
    <ul className={className}>
      {headerMenu.map(({ slug, linkText, lineColor, href, menu }, pkey) => (
        <li
          key={pkey}
          className={classNames("header__menu-item", {
            "header__menu-item--has-children": !!menus[menu],
          })}
        >
          <Link
            key={`header-link-${slug}`}
            to={href || slug}
            data-link-text={linkText}
            className={linkClass({ slug, lineColor, activeItem })}
            tabIndex={disabled && -1}
          >
            {linkText}
          </Link>
          {menus[menu] && <DropdownMenu menu={menus[menu]} slug={slug} />}
        </li>
      ))}
      <li className={linkClass({ slug: "login" })}>
        <a
          href="https://sbgsoftware.com/login"
          target="_blank"
          rel="noopener noreferrer"
          data-link-text="Cloud Management Login"
        >
          Login
        </a>
      </li>
    </ul>
  );
};

export default HeaderMenu;
