import http from "./http.services";
import * as constants from "../constants/resources.constants";

const getProducts = async (
  pageNumber: number = 1,
  pageSize = 100,
  search?: string
): Promise<object[]> => {
  const { data: products } = await http.get(constants.PRODUCTS, {
    params: {
      page_number: pageNumber,
      page_size: pageSize,
      search: search,
    },
  });
  return products;
};

const getProduct = async (): Promise<object[]> => {
  const { data: products } = await http.get(constants.PRODUCT, {
    params: {},
  });
  return products;
};

export const productsService = { getProducts, getProduct };
