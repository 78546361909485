import { FC } from "react";
import FollowOnLinks from "./FollowOnLinks";

export interface AboutProps {
  title: String;
  text: string;
}

const About: FC<AboutProps> = ({ title, text }) => {
  return (
    <div className="about text--dark">
      <h1 className="about__title">{title}</h1>
      <div className="about__text" dangerouslySetInnerHTML={{ __html: text }} />
      <FollowOnLinks pageTitle={title} summary={title} />
    </div>
  );
};

export default About;
