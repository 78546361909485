import axios from "axios";
import qs from "querystring";

const get = async (url: string, data: object) => {
    return await axios.get(url, data);
};

const post = async (url: string, data: object, headers?: object) => {
    return await axios.post(url, data, headers);
};

const put = async (url: string, data: object, headers?: object) => {
    return await axios.put(url, data, headers);
};

const patch = async (url: string, data: object, headers?: object) => {
    return await axios.patch(url, data, headers);
};

const remove = async (url: string, data: object) => {
    return await axios.delete(url, data);
};

axios.interceptors.request.use((config) => {
    return {
        ...config,
        paramsSerializer: (params) => qs.stringify(params)
    };
});

axios.interceptors.response.use(
    (res) => res,
    (error) => {
        return Promise.reject(error.response);
    }
);

const httpService = {
    get,
    post,
    put,
    patch,
    delete: remove
};

export default httpService;
