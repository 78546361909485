import { useEffect, useState } from "react";
import { blogsService } from "../../services/blogs.service";
import { toBlog } from "../../helpers/parse-data";

export interface Blogs {
  id: string;
  date: string;
  title: string;
  shortTitle: string;
  content: string;
  slug: string;
  type: string;
  youtube_video: string;
  image: {
    url: string;
    sizes: {
      [key: string]: string;
    };
  };
}

export interface UseBlogs {
  blogs: Blogs[];
  loading: boolean;
  error: any;
}

const useBlogs = (
  pageNumber: number,
  pageSize: number,
  search?: string
): UseBlogs => {
  const [blogs, setBlogs] = useState<Blogs[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    const getBlogs = async () => {
      setLoading(true);
      setError(false);

      try {
        const response = await blogsService.getBlogs(
          pageNumber,
          pageSize,
          search
        );
        if (response) setBlogs(toBlog(response));
      } catch (error) {
        setError(error);
      }
      setLoading(false);
    };

    getBlogs();
  }, [pageNumber, pageSize, search]);

  return { blogs, loading, error };
};

export default useBlogs;
