import { FC } from "react";
import useBlogs from "../../hooks/blogs/useBlogs";
import BlogSingle from "../../templates/BlogSingle";
import Error404 from "../../templates/Error404";

export interface BlogsProps {}

const BlogsPage: FC<BlogsProps> = ({ ...props }) => {
  const { blogs, loading } = useBlogs(1, 100);
  const blog = blogs?.find(
    (el) => el.slug === (props as any).match.params.slug
  );

  if (!blog && !loading) {
    return <Error404 {...props} />;
  }

  return <BlogSingle {...blog} postType="blog" {...props} />;
};

export default BlogsPage;
