import http from "./http.services";
import * as constants from "../constants/resources.constants";

const getNews = async (
  pageNumber: number = 1,
  pageSize = 100,
  search?: string
): Promise<object[]> => {
  const { data: news } = await http.get(constants.NEWS, {
    params: {
      page_number: pageNumber,
      page_size: pageSize,
      search: search,
    },
  });
  return news;
};

export const newsService = { getNews };
