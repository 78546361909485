import { Component } from "react";
import classNames from "classnames";

import PostLink from "../PostLink";

class CarouselContent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rolloverHidden: true,
      rolloverVisible: false,
    };

    this.rolloverShow = this.rolloverShow.bind(this);
    this.rolloverHide = this.rolloverHide.bind(this);
    this.expandContent = this.expandContent.bind(this);
    this.collapseContent = this.collapseContent.bind(this);
  }

  componentDidMount() {
    this.resetRollover();
  }

  resetRollover() {
    if (this.rolloverWrapper) {
      if (this.props.hasRollover) {
        this.rolloverHide();
      } else {
        this.expandContent();
      }
    }
  }

  componentWillUnmount() {
    this.props.setActiveHover(null);
  }

  rolloverShow() {
    if (
      this.props.hasRollover ||
      (this.props.internalLinks && this.props.position > 0)
    ) {
      this.props.stopCarousel();
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.setState({
          rolloverVisible: true,
        });
      }, 500);
      this.setState({ rolloverHidden: false });
      this.expandContent();
      this.props.setActiveHover(this.props.position);
    }
  }

  rolloverHide() {
    if (
      this.props.hasRollover ||
      (this.props.internalLinks && this.props.position > 0)
    ) {
      this.setState({ rolloverHidden: true, rolloverVisible: false });
      clearTimeout(this.timer);
      this.props.startCarousel();
      this.collapseContent();
      this.props.setActiveHover(null);
    }
  }

  expandContent() {
    const element = this.contentDiv;
    if (element) {
      element.style.height = element.scrollHeight + 3 + "px";
      element.setAttribute("data-collapsed", "false");
    }
  }

  collapseContent() {
    const element = this.contentDiv;
    if (element) {
      element.style.height = 0 + "px";
      element.setAttribute("data-collapsed", "true");
    }
  }

  onInternalLinkClick = () =>
    this.props.goToSlide(this.props.slideIndex, this.props.position);

  render() {
    const {
      position,
      title,
      children,
      readMoreLink,
      linkText,
      hasRollover,
      pageName,
      supIcon,
      supTitle,
      internalLinks,
    } = this.props;

    const linkMiscProps = {
      onFocus: this.rolloverShow,
      onBlur: this.rolloverHide,
    };

    const rolloverClass = classNames({
      rollover: true,
      "rollover--hidden": this.state.rolloverHidden && false,
      "rollover--visible": this.state.rolloverVisible || true,
    });

    const titleClass = classNames("car-content__title", {
      [`car-content__title--${position}`]: true,
    });

    const linkClass = classNames("car-content__carousel-link", {
      "car-content__carousel-link--cover": position > 0,
    });

    return (
      <div
        className={`car-content car-content--pos-${position} car-content--page-${pageName}`}
        onMouseLeave={position > 0 ? this.rolloverHide : undefined}
        onMouseEnter={position > 0 ? this.rolloverShow : undefined}
      >
        <div
          onMouseLeave={position === 0 ? this.rolloverHide : undefined}
          onMouseEnter={position === 0 ? this.rolloverShow : undefined}
          className="car-content__wrapper"
        >
          {position === 0 && supIcon && (
            <div className="car-content__sup">
              <img
                className="car-content__sup-icon"
                alt="carousel"
                src={supIcon.url}
              />
              <h1 className="car-content__sup-title">{supTitle}</h1>
            </div>
          )}
          {readMoreLink && !internalLinks && (
            <PostLink
              {...readMoreLink}
              miscProps={linkMiscProps}
              className={linkClass}
            >
              <h2 className={titleClass}>{title}</h2>
            </PostLink>
          )}
          {internalLinks && (
            <button onClick={this.onInternalLinkClick} className={linkClass}>
              <h2 className={titleClass}>{title}</h2>
            </button>
          )}
          {!readMoreLink && !internalLinks && (
            <h2 className={titleClass}>{title}</h2>
          )}
          {(position > 0 && !hasRollover) ||
            (position !== 2 && (
              <div
                className="car-content__rollover-wrapper"
                ref={(el) => (this.rolloverWrapper = el)}
              >
                <div
                  className={hasRollover ? rolloverClass : undefined}
                  ref={(el) => (this.contentDiv = el)}
                >
                  <div className="car-content__rollover-text">{children}</div>
                  {readMoreLink && (
                    <PostLink
                      {...readMoreLink}
                      className="car-content__read-more-link"
                      miscProps={linkMiscProps}
                    >
                      {linkText || "Read More"}
                    </PostLink>
                  )}
                </div>
              </div>
            ))}
        </div>
      </div>
    );
  }
}

export default CarouselContent;
