import { FC } from "react";
import useCaseStudies from "../../hooks/caseStudies/useCaseStudies";
import NewsSingle from "../../templates/NewsSingle";
import Error404 from "../../templates/Error404";

export interface BlogsProps {}

const BlogsPage: FC<BlogsProps> = ({ ...props }) => {
  const { caseStudies, loading } = useCaseStudies(1, 100);
  const caseStudy = caseStudies?.find(
    (el) => el.slug === (props as any).match.params.slug
  );

  if (!caseStudies && !loading) {
    return <Error404 {...props} />;
  }

  return (
    <NewsSingle
      {...caseStudy}
      postType="casestudies"
      options={{ noDates: true }}
      {...props}
    />
  );
};

export default BlogsPage;
