import { useEffect, useState } from "react";
import { productsService } from "../../services/products.service";
import { toHash } from "../../helpers/parse-data";

export interface Product {
  id: string;
  date: string;
  title: string;
  shortTitle: string;
  content: string;
  slug: string;
  type: string;
  youtube_video: string;
  image: {
    url: string;
    sizes: {
      [key: string]: string;
    };
  };
}

export interface UseProduct {
  product: Product | undefined;
  loading: boolean;
  error: any;
}

const useProduct = (): UseProduct => {
  const [product, setProduct] = useState<Product>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    const getProduct = async () => {
      setLoading(true);
      setError(false);

      try {
        const response = await productsService.getProduct();
        if (response) setProduct(toHash(response));
      } catch (error) {
        setError(error);
      }
      setLoading(false);
    };

    getProduct();
  }, []);

  return { product, loading, error };
};

export default useProduct;
