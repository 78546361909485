import { FC } from "react";
import siteLoaderImage from "../../../img/siteLoader.gif";

import styles from "./siteLoader.module.scss";

export interface SiteLoaderProps {
  hidden?: boolean;
}

const SiteLoader: FC<SiteLoaderProps> = ({ hidden }) => {
  return (
    <div
      id="siteLoader"
      className={`${styles.siteLoader} ${hidden ? styles.hidden : ""}`}
    >
      <img alt="Site Loader" className={styles.image} src={siteLoaderImage} />
    </div>
  );
};

export default SiteLoader;
