import { useEffect, useState } from "react";
import { caseStudiesService } from "../../services/caseStudies.service";
import { toNews } from "../../helpers/parse-data";

export interface CaseStudies {
  id: string;
  date: string;
  title: string;
  shortTitle: string;
  content: string;
  slug: string;
  type: string;
  youtube_video: string;
  image: {
    url: string;
    sizes: {
      [key: string]: string;
    };
  };
}

export interface UseCaseStudies {
  caseStudies: CaseStudies[];
  loading: boolean;
  error: any;
}

const useCaseStudies = (
  pageNumber: number,
  pageSize: number,
  search?: string
): UseCaseStudies => {
  const [caseStudies, setCaseStudies] = useState<CaseStudies[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    const getCaseStudies = async () => {
      setLoading(true);
      setError(false);

      try {
        const response = await caseStudiesService.getCaseStudies(
          pageNumber,
          pageSize,
          search
        );
        if (response) setCaseStudies(toNews(response));
      } catch (error) {
        setError(error);
      }
      setLoading(false);
    };

    getCaseStudies();
  }, [pageNumber, pageSize, search]);

  return { caseStudies, loading, error };
};

export default useCaseStudies;
