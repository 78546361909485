import { useEffect, useState } from "react";
import { newsService } from "../../services/news.service";
import { toNews } from "../../helpers/parse-data";

export interface News {
  id: string;
  date: string;
  title: string;
  shortTitle: string;
  content: string;
  slug: string;
  type: string;
  youtube_video: string;
  image: {
    url: string;
    sizes: {
      [key: string]: string;
    };
  };
}

export interface UseNews {
  news: News[];
  loading: boolean;
  error: any;
}

const useNews = (
  pageNumber: number,
  pageSize: number,
  search?: string
): UseNews => {
  const [news, setNews] = useState<News[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    const getNews = async () => {
      setLoading(true);
      setError(false);

      try {
        const response = await newsService.getNews(
          pageNumber,
          pageSize,
          search
        );
        if (response) setNews(toNews(response));
      } catch (error) {
        setError(error);
      }
      setLoading(false);
    };

    getNews();
  }, [pageNumber, pageSize, search]);

  return { news, loading, error };
};

export default useNews;
