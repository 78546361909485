import { Component } from "react";
import classnames from "classnames";

const cache = {};

class BackgroundImage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoaded: Boolean(cache[props.imageUrl]),
    };
  }

  onLoad = (e) => {
    cache[this.props.imageUrl] = true;
    this.setState({ isLoaded: true });
  };

  componentDidMount() {
    if (this.img.complete) {
      this.onLoad();
    }
  }

  render() {
    const { isLoaded } = this.state;
    const {
      // thumbnailUrl,
      imageUrl,
      className,
      zoomed,
      filter,
      children,
      alt,
    } = this.props;

    // const thumbnailStyle = {
    //   backgroundImage: `url("${thumbnailUrl}")`
    // };

    const filterClass = classnames("background-image__filter", {
      "background-image__filter--active": filter,
    });

    const componentClass = classnames("background-image", className, {
      "background-image--zoomed": zoomed,
      "background-image--loaded": isLoaded,
    });

    return (
      <div className={componentClass}>
        {/* <div className="background-image__thumbnail" style={thumbnailStyle} /> */}
        <img
          className="background-image__image"
          src={imageUrl}
          onLoad={this.onLoad}
          ref={(el) => (this.img = el)}
          alt={alt}
        />
        <div className={filterClass} />
        <div className="background-image__content">{children}</div>
      </div>
    );
  }
}

export default BackgroundImage;
