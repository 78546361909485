import { Component } from "react";
import mapMarkerPin from "../img/map-marker-pin.png";
import GoogleMapReact from "google-map-react";

const Pin = () => (
  <div>
    <img
      alt="map marker pin"
      className="location-map__marker-pin"
      src={mapMarkerPin}
    />
  </div>
);

class LocationMap extends Component {
  static defaultProps = {
    location: { lat: 59.95, lng: 30.33 },
    zoom: 13,
  };

  render() {
    return (
      <div className="location-map">
        <GoogleMapReact
          bootstrapURLKeys={{ key: this.props.apiKey }}
          defaultCenter={this.props.location}
          defaultZoom={this.props.zoom}
          options={{
            zoomControl: false,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false,
          }}
        >
          <Pin lat={this.props.location.lat} lng={this.props.location.lng} />
        </GoogleMapReact>
      </div>
    );
  }
}

export default LocationMap;
