import { useEffect, useState } from "react";
import { solutionsService } from "../../services/solutions.service";
import { toHash } from "../../helpers/parse-data";

export interface Solution {
  id: string;
  date: string;
  title: string;
  shortTitle: string;
  content: string;
  slug: string;
  type: string;
  youtube_video: string;
  image: {
    url: string;
    sizes: {
      [key: string]: string;
    };
  };
}

export interface UseSolution {
  solution: Solution | undefined;
  loading: boolean;
  error: any;
}

const useSolution = (): UseSolution => {
  const [solution, setSolution] = useState<Solution>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    const getSolution = async () => {
      setLoading(true);
      setError(false);

      try {
        const response = await solutionsService.getSolution();
        if (response) setSolution(toHash(response));
      } catch (error) {
        setError(error);
      }
      setLoading(false);
    };

    getSolution();
  }, []);

  return { solution, loading, error };
};

export default useSolution;
