import http from "./http.services";
import * as constants from "../constants/resources.constants";

const getSolutions = async (): Promise<object[]> => {
  const { data: solutions } = await http.get(constants.SOLUTIONS, {});
  return solutions;
};

const getSolution = async (): Promise<object[]> => {
  const { data: solutions } = await http.get(constants.SOLUTION, {});
  return solutions;
};

export const solutionsService = { getSolutions, getSolution };
