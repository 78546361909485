import { FC } from "react";
import Content from "../../templates/Content";
import useBlogs from "../../hooks/blogs/useBlogs";

export interface BlogsProps {}

const BlogsSinglePage: FC<BlogsProps> = ({ ...props }) => {
  const { blogs } = useBlogs(1, 100);
  return (
    <Content
      data={blogs}
      postType="blog"
      options={{ noDates: true }}
      {...props}
    />
  );
};

export default BlogsSinglePage;
