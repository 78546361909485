import { FC } from "react";
import Content from "../../templates/Content";
import useCaseStudies from "../../hooks/caseStudies/useCaseStudies";

export interface CaseStudiesPageProps {}

const CaseStudiesPage: FC<CaseStudiesPageProps> = ({ ...props }) => {
  const { caseStudies } = useCaseStudies(1, 100);
  return (
    <Content
      data={caseStudies}
      postType="casestudies"
      options={{ noDates: true }}
      {...props}
    />
  );
};

export default CaseStudiesPage;
