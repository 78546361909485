import { Component } from "react";
import classNames from "classnames";
import nanoid from "nanoid";

import BackgroundImage from "../BackgroundImage";
import PlayIcon from "../img/Play";

class YoutubePlayerStandalone extends Component {
  constructor() {
    super();

    const playerId = nanoid();

    this.state = {
      isReady: false,
      isPlayerActive: false,
      youtubePlayerState: -1,
      playerId,
    };
    this.playVideo = this.playVideo?.bind(this);
    this.initYoutubeApi = this.initYoutubeApi?.bind(this);
    this.onPlayerReady = this.onPlayerReady?.bind(this);
    this.onPlayerStateChange = this.onPlayerStateChange?.bind(this);
  }

  componentDidMount() {
    this.initYoutubeApi();
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.props.oembed !== nextProps.oembed ||
      this.state.youtubePlayerState !== nextState.youtubePlayerState ||
      this.state.isReady !== nextState.isReady
    );
  }

  enableYoutubeJsApi(embed) {
    const { playerId } = this.state;

    return embed.replace(
      'feature=oembed"',
      `enablejsapi=1&showinfo=0&modestbranding=1&autohide=1&controls=1" id="${playerId}"`
    );
  }

  initYoutubeApi() {
    // Inject YouTube API script
    if (window && window.YT) {
      this.registerPlayer();
    } else {
      if (document) {
        let tag = document.createElement("script");
        tag.src = "//www.youtube.com/player_api";
        let firstScriptTag = document.getElementsByTagName("script")[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
      }

      if (window) {
        window.onYouTubePlayerAPIReady = () => {
          // create the global player from the specific iframe (#video)
          this.registerPlayer();
        };
      }
    }
  }

  registerPlayer() {
    const { playerId } = this.state;
    this.player = new window.YT.Player(playerId, {
      events: {
        // call this function when player is ready to use
        onReady: this.onPlayerReady,
        onStateChange: this.onPlayerStateChange,
      },
    });
  }

  onPlayerStateChange(event) {
    /*
        Youtube API:
       -1 (unstarted)
        0 (ended)
        1 (playing)
        2 (paused)
        3 (buffering)
        5 (video cued)
    */
    this.setState({ youtubePlayerState: event.data });
  }

  isPlaying(eventId) {
    return !(eventId === -1 || eventId === 0 || eventId === 2);
  }

  isEnded(eventId) {
    return eventId === 0;
  }

  playVideo() {
    this.youtubePlayerRef.focus();
    this.player && this.player.playVideo();
  }

  render() {
    const { oembed, featured, coverImage } = this.props;
    const { isReady, youtubePlayerState } = this.state;

    const isPlayerActive = youtubePlayerState > 0;

    const embedString = this.enableYoutubeJsApi(oembed);

    const playerClass = classNames("youtube-player-standalone", {
      "youtube-player-standalone--featured": featured,
    });
    const videoClass = classNames("youtube-player-standalone__video", {
      "youtube-player-standalone__video--featured": featured,
      "youtube-player-standalone__video--active": isPlayerActive,
    });
    const coverImageClass = classNames(
      "youtube-player-standalone__cover-image",
      {
        "youtube-player-standalone__cover-image--active": isPlayerActive,
        "youtube-player-standalone__cover-image--no-cover": !coverImage,
      }
    );
    const linkClickHandler = !isReady ? () => {} : this.playVideo;

    const showCoverImage =
      this.state.youtubePlayerState === -1 ||
      this.state.youtubePlayerState === 0 ||
      this.state.youtubePlayerState === 2;

    return (
      <div className={playerClass} ref={(el) => (this.youtubePlayerRef = el)}>
        {showCoverImage && (
          <BackgroundImage
            thumbnailUrl={coverImage && coverImage.sizes.thumbnail}
            imageUrl={coverImage ? coverImage.url : ""}
            alt={coverImage ? coverImage.alt : "placeholder-image"}
            className={coverImageClass}
          >
            <div
              className="youtube-player-standalone__link"
              onClick={linkClickHandler}
            >
              {isReady && (
                <div className="youtube-player__play-icon">
                  <PlayIcon />
                </div>
              )}
            </div>
          </BackgroundImage>
        )}
        <div className={videoClass}>
          <div dangerouslySetInnerHTML={{ __html: embedString }} />
        </div>
      </div>
    );
  }
}

export default YoutubePlayerStandalone;
