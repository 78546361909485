import { FC } from "react";
import LongForm from "../../templates/LongForm";
import useSolution from "../../hooks/solution/useSolution";
import withLayout from "../../templates/withLayout";
import SiteLoader from "../common/siteLoader";

export interface SolutionPageProps {}

const SolutionPage: FC<SolutionPageProps> = ({ ...props }) => {
  const { solution } = useSolution();

  if (!solution) return <SiteLoader />;

  return <LongForm data={solution} {...props} />;
};

export default withLayout(SolutionPage, {
  template: "long-form",
  footer: { sticky: true, lightText: true },
  header: { hasGradient: false },
});
