import { Component } from "react";

import MediaQuery from "react-responsive";

import withLayout from "./withLayout";

import ContactForm from "../components/ContactForm";
import MobileContact from "../components/mobile/MobileContact";

const Address = ({ title, address, tel }) => (
  <div className="contact__address">
    <h3 className="contact__address_title">{title}</h3>
    <div className="p--sm">
      {address && <div dangerouslySetInnerHTML={{ __html: address }} />}
      {tel && <a href={`tel:${tel}`}>{tel}</a>}
    </div>
  </div>
);

class ContactPage extends Component {
  render() {
    const { data = {}, settings } = this.props;

    let mapsLocation;

    if (data.location) {
      mapsLocation = {
        lat: parseFloat(data.location.lat),
        lng: parseFloat(data.location.lng),
      };
    }

    const {
      addressTitle,
      address,
      telephoneNumber,
      addressTitleSecondary,
      addressSecondary,
      telephoneNumberSecondary,
    } = data;

    return (
      <div>
        <MediaQuery maxWidth={1023}>
          <MobileContact
            {...data}
            mapsLocation={mapsLocation}
            googleApiKey={settings?.googleApiKey}
          />
        </MediaQuery>
        <MediaQuery minWidth={1024}>
          <div className="contact__content-wrapper">
            <div className="contact__info">
              <div className="contact__contact-form text--dark">
                <h2 className="contact__contact-form-title">
                  {data.contactFormTitle}
                </h2>
                <div className="p--sm">
                  <ContactForm />
                </div>
              </div>
            </div>
            <div className="contact__addresses text--light">
              <h3 className="contact__address_heading">Contact us</h3>
              <Address
                title={addressTitle}
                address={address}
                tel={telephoneNumber}
              />
              {addressTitleSecondary && (
                <Address
                  title={addressTitleSecondary}
                  address={addressSecondary}
                  tel={telephoneNumberSecondary}
                />
              )}
            </div>
          </div>
        </MediaQuery>
      </div>
    );
  }
}

export default withLayout(ContactPage, {
  dark: true,
  header: { shadow: false },
  footer: { shadow: false },
});
