import { FC } from "react";
import Home from "../../templates/Home";
import withLayout from "../../templates/withLayout";
import useHomePage from "../../hooks/homePage/useHomePage";
import SiteLoader from "../common/siteLoader";

export interface HomePageProps {}

const HomePage: FC<HomePageProps> = () => {
  const { homePage } = useHomePage();

  if (!homePage) return <SiteLoader />;

  return <Home {...homePage} />;
};

export default withLayout(HomePage, { classes: "home" });
