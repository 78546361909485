import { Component } from "react";
import { Link } from "react-router-dom";
import format from "date-fns/format";
import classNames from "classnames";

import FullHeight from "../FullHeight";
import BackgroundImage from "../BackgroundImage";

class MobileNews extends Component {
  constructor() {
    super();

    this.state = {
      activeResult: 0,
      resultHeight: 190,
    };
  }

  componentDidMount() {
    window.scroll(0, 0);
  }

  render() {
    const { results, options } = this.props;
    const { activeResult } = this.state;

    const resultClass = (i) =>
      classNames("mobile-news__result", {
        "mobile-news__result--active": activeResult === i,
      });

    return (
      <ul className="mobile-news" ref={(el) => (this.accordion = el)}>
        {results.map((result, i) => {
          const ResultTag = i === 0 ? FullHeight : "div";
          return (
            <BackgroundImage
              key={`search-result-${result.id}`}
              className="mobile-news__result-wrap"
              imageUrl={result.image.sizes.large}
              thumbnailUrl={result.image.sizes.thumbnail}
              tagName="li"
              color="DARK_FADE"
            >
              <ResultTag className={resultClass(i)}>
                {!options.noDates && (
                  <div className="mobile-news__result-date">
                    {format(result.date, "D MMMM YYYY")}
                  </div>
                )}
                <Link to={`/${result.type}/${result.slug}`}>
                  <h2
                    dangerouslySetInnerHTML={{
                      __html: result.shortTitle || result.title,
                    }}
                    className="mobile-news__result-title"
                  />
                </Link>
              </ResultTag>
            </BackgroundImage>
          );
        })}
      </ul>
    );
  }
}

export default MobileNews;
