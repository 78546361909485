const YoutubePlayerIframe = ({ youtube_video, title }) => {
  if (!youtube_video) {
    return false;
  }

  return (
    <div className="vid-overlay">
      <h3>
        Watch : <span>{title}</span>
      </h3>
      <iframe
        width="560"
        height="315"
        src={"https://www.youtube.com/embed/" + youtube_video + "?rel=0"}
        title={title}
        frameborder="0"
        allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen="1"
      />
    </div>
  );
};

export default YoutubePlayerIframe;
