import { useEffect, useState } from "react";
import { solutionsService } from "../../services/solutions.service";
import { toProductsPage } from "../../helpers/parse-data";

export interface Solutions {
  id: string;
  date: string;
  title: string;
  shortTitle: string;
  content: string;
  slug: string;
  type: string;
  youtube_video: string;
  image: {
    url: string;
    sizes: {
      [key: string]: string;
    };
  };
}

export interface UseSolutions {
  solutions: Solutions[];
  loading: boolean;
  error: any;
}

const useSolutions = (): UseSolutions => {
  const [solutions, setSolutions] = useState<Solutions[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    const getSolutions = async () => {
      setLoading(true);
      setError(false);

      try {
        const response = await solutionsService.getSolutions();
        if (response) setSolutions(toProductsPage(response));
      } catch (error) {
        setError(error);
      }
      setLoading(false);
    };

    getSolutions();
  }, []);

  return { solutions, loading, error };
};

export default useSolutions;
