import Twitter from "./img/Twitter";
import LinkedIn from "./img/LinkedIn";
import Facebook from "./img/Facebook";
import Email from "./img/Email";
/*

Use this component where you want them to appear - 
    <FollowOnLinks />
let FollowOnLinksData = [
{
    title : "LinkedIn",
    href : "https://www.linkedin.com/shareArticle?mini=true&url="+encodeURIComponent(window.location.href)+"&summary="+"&source=https://sbgsportssoftware.com/",
    className : "footer__link footer__link--social",
    spanClassName : "fa fa-linkedin"
}
];
*/

const FollowOnLinks = ({
  pageTitle,
  summary,
  showLabel = true,
  className = "footer__link footer__link--social",
}) => (
  <div className="follow-on-links">
    {showLabel && (
      <h6 className="screen-reader-text">
        <span>Share</span>
        <span className="fa fa-share" />
      </h6>
    )}
    <ul>
      <FollowlLink
        url={
          "https://www.linkedin.com/shareArticle?mini=true&url=" +
          window.location.href +
          "&summary=" +
          summary +
          "&source=" +
          window.location.href
        }
        title="Share on LinkedIn"
        className={className}
        Icon={LinkedIn}
      />

      <FollowlLink
        url={
          "https://www.facebook.com/sharer.php?s=100&p[title]=" +
          encodeURIComponent(pageTitle) +
          "&[summary]=" +
          summary +
          "&[url]=" +
          window.location.href
        }
        title="Share on Facebook"
        className={className}
        Icon={Facebook}
      />

      <FollowlLink
        url={
          "https://twitter.com/intent/tweet?url=" +
          window.location.href +
          "&original_referer=" +
          encodeURIComponent(pageTitle) +
          "&status=" +
          window.location.href
        }
        title="Share on Twitter"
        className={className}
        Icon={Twitter}
      />

      <FollowlMailLink
        url={window.location.href}
        title="Share this link"
        className={className}
        Icon={Email}
      />
    </ul>
  </div>
);

/*
<FollowlLink      
  url={"https://www.stumbleupon.com/submit?url="+window.location.href+"&title="+encodeURIComponent(pageTitle)}
  title="Share on StumblUpon"
  className={className}                      
  Icon={StumbleUpon}
/>

<FollowlLink      
  url={"https://plus.google.com/share?url="+window.location.href}
  title="Share on GooglePlus"
  className={className}                      
  Icon={GooglePlus}
/>
 */

const FollowlLink = ({
  url,
  title,
  className,
  Icon,

  openShare = function (e, url, title) {
    e.preventDefault();
    window.open(
      url,
      "{title}",
      "width=600,height=600,menubar=no,toolbar=no,resizable=yes,scrollbars=yes,location=1,status=1,modal=yes,alwaysRaised=yes"
    );
    return false;
  },
}) => (
  <li className="icon li">
    <a
      href={url}
      className={className}
      title={title}
      rel="noopener noreferrer"
      target="_blank"
      onClick={(e) => {
        openShare(e, url, title);
      }}
    >
      <span className="sr-only">{title}</span>
      <Icon color={"currentColor"} />
    </a>
  </li>
);

const FollowlMailLink = ({ url, title, className, Icon }) => (
  <li className="icon li">
    <a
      ///href={"&#109;ail&#116;o?%&#55;3&#117;b&#106;%65c%7&#52;="+encodeURIComponent(title)+"&bod&#121;=S&#104;a&#114;e t&#104;is %6Ci%6Ek" + encodeURIComponent(url)}
      href={
        "mailto:?subject=" +
        encodeURIComponent(title) +
        "&body=Share%20This%20Link%20" +
        encodeURIComponent(url)
      }
      className={className}
      title={title}
      rel="noopener noreferrer"
      target="_blank"
    >
      <span className="sr-only">{title}</span>
      <Icon color={"currentColor"} />
    </a>
  </li>
);

export default FollowOnLinks;
