import { FC } from "react";
import Tiles from "../../templates/Tiles";
import useSolutions from "../../hooks/solutions/useSolutions";
import withLayout from "../../templates/withLayout";
import SiteLoader from "../common/siteLoader";

export interface SolutionsProps {}

const Solutions: FC<SolutionsProps> = ({ ...props }) => {
  const { solutions } = useSolutions();

  if (!solutions.length) return <SiteLoader />;

  return <Tiles tiles={solutions} {...props} />;
};

export default withLayout(Solutions, { header: { hasGradient: false } });
