import { Component } from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";

import Logo from "../assets/icons/images/Logo.svg";

import HeaderMenu from "./header/HeaderMenu";

/**
 {this.props.hasShadow && (
          <div className="shadow-wrapper">
            <nav className="header__nav header__nav--shadow">
              <HeaderMenu
                disabled={true}
                activeItem={this.props.headerMenuItem}
                className="header__menu"
                menus={this.props.menus}
              />
            </nav>
          </div>
        )}
 */

class Header extends Component {
  render() {
    const { hasGradient } = this.props;

    return (
      <header
        className={classnames("header", {
          "header--has-gradient": hasGradient,
        })}
      >
        <Link to="/">
          <div className="header__logo">
          <img src={Logo} alt={"SBG a Catapult Company"}/>
          </div>
        </Link>
        <nav className="header__nav">
          <HeaderMenu
            activeItem={this.props.headerMenuItem}
            className="header__menu"
            menus={this.props.menus}
          />
        </nav>
      </header>
    );
  }
}

export default Header;
