import { useEffect, useState } from "react";
import { aboutService } from "../../services/about.service";
import { toAboutPage } from "../../helpers/parse-data";

export interface About {
  id: string;
  date: string;
  title: string;
  shortTitle: string;
  content: string;
  slug: string;
  type: string;
  youtube_video: string;
  image: {
    url: string;
    sizes: {
      [key: string]: string;
    };
  };
}

export interface UseAbout {
  about: About | undefined;
  loading: boolean;
  error: any;
}

const useAbout = (): UseAbout => {
  const [about, setAbout] = useState<About>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    const getAbout = async () => {
      setLoading(true);
      setError(false);

      try {
        const response = await aboutService.getAbout();
        if (response) setAbout(toAboutPage(response));
      } catch (error) {
        setError(error);
      }
      setLoading(false);
    };

    getAbout();
  }, []);

  return { about, loading, error };
};

export default useAbout;
