import { Component } from "react";

import { CAROUSEL_INTERVAL_TIME_MS } from "../constants/carousel.constants";

import CarouselNav from "./carousel/CarouselNav";
import Carousel from "./carousel/Carousel";
import BackgroundImage from "./BackgroundImage";

class AboutCarousel extends Component {
  goNext = () => {
    this.carousel.nextSlide();
  };

  goPrev = () => {
    this.carousel.prevSlide();
  };

  addGlobalCarousel = (id, carousel) => {
    if (!this.carousel) {
      this.carousel = carousel;
    }
  };

  render() {
    const { aboutCarousel } = this.props;
    return (
      <div>
        <Carousel
          className="about-carousel"
          autoplay={true}
          interval={CAROUSEL_INTERVAL_TIME_MS}
          initialSlide={0}
          addGlobalCarousel={this.addGlobalCarousel}
        >
          {aboutCarousel.map(({ image }) => (
            <div className="about-carousel__slide">
              <BackgroundImage imageUrl={image.url} />
            </div>
          ))}
        </Carousel>
        <CarouselNav goPrev={this.goPrev} goNext={this.goNext} />
      </div>
    );
  }
}

export default AboutCarousel;
