import { FC } from "react";
import Collage from "../components/Collage";

export interface HomeProps {
  carousel: [];
  oembed: string;
  videoCoverImage: {};
}

const Home: FC<HomeProps> = ({ carousel, oembed, videoCoverImage }) => {
  return (
    <Collage
      slides={carousel}
      video={{ oembed, featured: false, coverImage: videoCoverImage }}
      hasRollover={true}
      pageName="home"
    />
  );
};

export default Home;
