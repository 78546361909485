import { Component } from "react";
import classNames from "classnames";
import MediaQuery from "react-responsive";

import ScrollBar from "./ScrollBar";

class Legal extends Component {
  render() {
    const { legal, hashLink, closeLegal, legalOpen } = this.props;

    if (!legal) return null;

    const doc = legal.find((el) => el.slug === hashLink);

    const closeBtnClass = classNames(
      "legal__close-icon hamburger hamburger--collapse",
      {
        "is-active": legalOpen,
      }
    );

    return !doc ? null : (
      <div className="legal">
        <div className="legal__section">
          <MediaQuery minWidth={1024}>
            <button
              onClick={closeLegal}
              className={closeBtnClass}
              type="button"
            >
              <span className="sr-only">Close Legal</span>
              <span className="hamburger-box">
                <span className="hamburger-inner" />
              </span>
            </button>
          </MediaQuery>
          <h1
            className="legal__title"
            dangerouslySetInnerHTML={{ __html: doc.title.rendered }}
          />
          <div className="legal__content">
            <MediaQuery maxWidth={1023}>
              <div
                dangerouslySetInnerHTML={{ __html: doc.content.rendered }}
                className="legal__content-inner"
              />
            </MediaQuery>
            <MediaQuery minWidth={1024}>
              <ScrollBar>
                <div
                  className="legal__content-inner"
                  dangerouslySetInnerHTML={{ __html: doc.content.rendered }}
                />
              </ScrollBar>
            </MediaQuery>
          </div>
        </div>
      </div>
    );
  }
}

export default Legal;
