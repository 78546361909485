import { Link } from "react-router-dom";

const PostLink = ({
  type,
  slug,
  title,
  post_type,
  post_name,
  post_title,
  className,
  children,
  miscProps,
  ext,
}) => {
  type = type || post_type;
  slug = slug || post_name;
  title = children || title || post_title;
  ext = ext ? `/${ext}` : "";

  let href;

  if (type === "page") {
    href = `/${slug}${ext}`;
  } else {
    href = `/${type}/${slug}${ext}`;
  }

  return (
    <Link {...miscProps} className={className} to={href} data-link-text={title}>
      {title}
    </Link>
  );
};

export default PostLink;
