import { Component } from "react";
import classNames from "classnames";

import Carousel from "./Carousel";
import CarouselContent from "./CarouselContent";
import BackgroundImage from "../BackgroundImage";

const imagePosition = (i, shift) =>
  (i + shift ? 1 : 0) % 2 === 0 ? "left" : "right";
export const getOffset = (position, total) => {
  if (total < 4) {
    return position;
  }
  if (position === 0) {
    return 0;
  }
  if (position === 3) {
    return 1;
  }
  if (position === 1) {
    return 2;
  }
  if (position === 2) {
    return 3;
  }

  return total > 3 ? position + 1 : position;
};

class MixedCarousel extends Component {
  render() {
    const {
      imageArray,
      contentArray,
      position,
      color,
      addGlobalCarousel,
      removeGlobalCarousel,
      hasRollover,
      startCarousel,
      stopCarousel,
      pageName,
      vertical,
      isHovered,
      setActiveHover,
      rtl,
      pageTitle,
      pageIcon,
      internalLinks,
      goToSlide,
    } = this.props;

    const imageCarouselClass = classNames("mixed-carousel__carousel", {
      "mixed-carousel__carousel--vertical": vertical,
    });

    const textCarouselClass = classNames("mixed-carousel__carousel", {
      "mixed-carousel__carousel--text": true,
      [`mixed-carousel__carousel--${color}`]: !!color,
      "mixed-carousel__carousel--no-rollover": !hasRollover && position === 0,
    });

    const isMobile = window.innerWidth < 1024;
    const totalSlides = imageArray.length;
    const initialSlide = getOffset(position, totalSlides);

    return (
      <div className={`mixed-carousel mixed-carousel--${position}`}>
        <div className={imageCarouselClass}>
          <Carousel
            carouselId={position}
            initialSlide={initialSlide}
            addGlobalCarousel={addGlobalCarousel}
            removeGlobalCarousel={removeGlobalCarousel}
            vertical={vertical}
            rtl={rtl}
          >
            {imageArray.map((image, i) => (
              <div key={`ImageCarousel-${position}-${image.id}`}>
                <BackgroundImage
                  imageUrl={image.url}
                  thumbnailUrl={image.sizes.thumbnail}
                  position={position > 0 && imagePosition(i, vertical)}
                  zoomed={position > 0 && isHovered}
                  color={!isMobile && isHovered && "DARK_FADE"}
                  className={`mixed-carousel__caoursel-image mixed-carousel__caoursel-image--${position}`}
                />
              </div>
            ))}
          </Carousel>
        </div>
        <div className={textCarouselClass}>
          <Carousel
            carouselId={position + "b"}
            initialSlide={initialSlide}
            addGlobalCarousel={addGlobalCarousel}
            removeGlobalCarousel={removeGlobalCarousel}
            fade={true}
          >
            {contentArray.map((content, index) => (
              <div key={`TextCarousel-${position}-${index}`}>
                <CarouselContent
                  title={content.title}
                  position={position}
                  key={`CarouselContent-${position}-${index}`}
                  hasRollover={hasRollover}
                  startCarousel={startCarousel}
                  stopCarousel={stopCarousel}
                  pageName={pageName}
                  readMoreLink={content.link}
                  linkText={content.linkText}
                  setActiveHover={setActiveHover}
                  supTitle={content.supTitle || pageTitle}
                  supIcon={content.supIcon || pageIcon}
                  internalLinks={internalLinks}
                  slideIndex={index}
                  goToSlide={goToSlide}
                >
                  <div
                    dangerouslySetInnerHTML={{ __html: content.textContent }}
                  />
                </CarouselContent>
              </div>
            ))}
          </Carousel>
        </div>
      </div>
    );
  }
}

export default MixedCarousel;
