import { FC } from "react";
import About from "../../templates/About";
import useAbout from "../../hooks/about/useAbout";

export interface AboutPageProps {}

const AboutPage: FC<AboutPageProps> = ({ ...props }) => {
  const { about } = useAbout();
  return <About data={about} {...props} />;
};

export default AboutPage;
