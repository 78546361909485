import { FC, useRef } from "react";
import Tiles from "../../templates/Tiles";
import useProducts from "../../hooks/products/useProducts";
import withLayout from "../../templates/withLayout";
import SiteLoader from "../common/siteLoader";
import Icon from "@sbgsportssoftware/icon";

export interface ProductsProps {}

const Products: FC<ProductsProps> = ({ ...props }) => {
  const { products } = useProducts();
  const productsRef = useRef<HTMLDivElement>(null);

  if (!products.length) return <SiteLoader />;

  const handleScrollTo = () => {
    productsRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  return (
    <div className={"productPage"}>
      <div className={"heading"}>
        <h1>Performance tools and video analysis software for sports teams</h1>
        <div className={"subtitleContainer"} onClick={handleScrollTo}>
          <div className={"subtitle"}>View Products</div>{" "}
          <Icon
            icon={{
              icon: ["fas", "chevron-down"],
              type: "FontAwesomeIcon",
              size: "1x",
            }}
            classes={"icon"}
          />
        </div>
      </div>
      <div ref={productsRef}>
        <Tiles tiles={products} {...props} />
      </div>
    </div>
  );
};

export default withLayout(Products, { header: { hasGradient: false } });
