const ArrowLeft = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="27"
    height="27"
    viewBox="0 0 80 80"
  >
    <path fill="currentColor" d="M50 80L11 40 50 0l10 10-30 30 30 30-10 10z" />
  </svg>
);

export default ArrowLeft;
