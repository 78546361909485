import { FC } from "react";
import useContact from "../../hooks/contact/useContact";
import Contact from "../../templates/Contact";

export interface ContactPageProps {}

const ContactPage: FC<ContactPageProps> = ({ ...props }) => {
  const { contact } = useContact();
  return <Contact data={contact} {...props} />;
};

export default ContactPage;
