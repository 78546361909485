import { Component, Children } from "react";
import classnames from "classnames";

class Slider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      current: props.initialSlide,
    };

    this.slideTo = this.slideTo.bind(this);
    this.slideNext = this.slideNext.bind(this);
    this.slidePrev = this.slidePrev.bind(this);
    this.onSlideEnd = this.onSlideEnd.bind(this);
    this.onKeyPress = this.onKeyPress.bind(this);
    this.onResize = this.onResize.bind(this);
    this.addSlideClasses = this.addSlideClasses.bind(this);
  }

  autoplayStart(interval) {
    this.autoplayTimeout = setTimeout(() => {
      this.slideNext();
      this.autoplayStart(interval);
    }, interval);
  }

  autoplayStop() {
    clearTimeout(this.autoplayTimeout);
  }

  addSlideClasses(children) {
    return Children.map(children, (child, index) => {
      const className = classnames(
        child.props.className,
        "react-simple-slider__slide",
        {
          "react-simple-slider__slide--horizontal": !this.props.vertical,
          "react-simple-slider__slide--vertical": this.props.vertical,
        }
      );

      return (
        <child.type
          {...child.props}
          className={className}
          key={`${index}-${child.key}`}
        />
      );
    });
  }

  updateWrapperSize() {
    const newSize = this.calculateSizes(this.slides);

    if (this.props.vertical) {
      // this.wrapperRef.style.height = newSize + 'px';
    } else {
      this.wrapperRef.style.width = newSize + "px";
    }
  }

  updateTranslate() {
    const newTranslate = this.calculateTranslate(this.state.current);

    if (this.props.vertical) {
      this.wrapperRef.style.transform = `translateY(-${newTranslate}px)`;
    } else {
      this.wrapperRef.style.transform = `translateX(-${newTranslate}px)`;
    }
  }

  calculateTranslate(slideIndex) {
    const slidesBefore = this.slides.slice(0, slideIndex + 1);
    const slidesAfter = this.slides.slice(slideIndex + 1, -1);

    return this.calculateSizes(
      this.props.reverseDirection ? slidesAfter : slidesBefore
    );
  }

  calculateSizes(slides) {
    if (this.props.vertical) {
      return slides.reduce(
        (height, slide) => height + this.getHeight(slide),
        0
      );
    }
    return slides.reduce((width, slide) => width + this.getWidth(slide), 0);
  }

  componentDidMount() {
    if (this.props.keyboardNav) {
      window.addEventListener("keydown", this.onKeyPress);
    }
    window.addEventListener("resize", this.onResize);
    if (this.props.autoplay) {
      this.autoplayStart(this.props.interval);
    }

    this.updateWrapperSize();
    this.updateTranslate();
  }

  componentWillUnmount() {
    if (this.props.keyboardNav) {
      window.removeEventListener("keydown", this.onKeyPress);
    }
    window.removeEventListener("resize", this.onResize);
    this.autoplayStop();
  }

  componentDidUpdate(prevProps, prevState) {
    const propHasChanged = (propName) => {
      return this.state[propName] !== prevState[propName];
    };

    const havePropsChanged = ["current", "windowWidth", "windowHeight"].reduce(
      (hasChanged, propName) => hasChanged || propHasChanged(propName),
      false
    );

    const hasNewChildren =
      this.props.children.length !== prevProps.children.length;

    const needUpdateTranslate = havePropsChanged || hasNewChildren;

    if (needUpdateTranslate) {
      this.updateTranslate();
      this.updateWrapperSize();
    }
  }

  onKeyPress({ keyCode }) {
    if (this.state.transitioning) {
      return;
    }
    if (keyCode === 37) {
      return this.slidePrev();
    }
    if (keyCode === 39) {
      return this.slideNext();
    }
  }

  queueTransition(fn) {
    this.onSlideStart();
    setTimeout(fn, 0);
    setTimeout(this.onSlideEnd, this.props.time);
  }

  slidePrev() {
    this.slideTo((this.state.current - 1) % this.slides.length);
  }

  slideNext() {
    this.slideTo((this.state.current + 1) % this.slides.length);
  }

  slideTo(i) {
    if (!this.state.transitioning) {
      return this.queueTransition(() => this.setState({ current: i }));
    }
  }

  goDirect(i) {
    this.setState({ current: i });
  }

  onSlideEnd() {
    this.setState({ transitioning: false });

    if (this.state.current === this.props.children.length) {
      setTimeout(() => this.goDirect(0), 0);
    }
    if (this.state.current === -1) {
      setTimeout(() => this.goDirect(this.props.children.length - 1), 0);
    }
  }

  onSlideStart() {
    return this.setState({ transitioning: true });
  }

  getWidth(el) {
    return el.getBoundingClientRect().width;
  }

  getHeight(el) {
    return el.getBoundingClientRect().height;
  }

  onResize() {
    this.setState({
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
    });
  }

  reverseSlidesIfNecessary(slides) {
    if (!this.props.reverseDirection) {
      return slides;
    }
    return slides.concat([]).reverse();
  }

  processChildren(children) {
    const totalSlides = children.length;

    if (totalSlides === 0) {
      return [];
    }

    const slidesWithDupes = [
      children[totalSlides - 1],
      ...children,
      children[0],
    ];

    return this.addSlideClasses(this.reverseSlidesIfNecessary(slidesWithDupes));
  }

  render() {
    const { transitioning } = this.state;
    const { children, time, easing, className, vertical } = this.props;

    const wrapperStyle = {};
    if (transitioning) {
      wrapperStyle.transition = `transform ${time}ms ${easing}`;
    }

    const sliderClass = classnames("react-simple-slider", {
      [className]: !!className,
    });
    const wrapperClass = classnames("react-simple-slider__wrapper", {
      "react-simple-slider__wrapper--horizontal": !vertical,
      "react-simple-slider__wrapper--vertical": vertical,
      "react-simple-slider__wrapper--transitioning": transitioning,
    });

    return (
      <div className={sliderClass}>
        <div
          ref={(el) => {
            if (el) {
              this.wrapperRef = el;
              this.slides = Array.from(el.children);
            }
          }}
          className={wrapperClass}
          style={wrapperStyle}
        >
          {this.processChildren(children)}
        </div>
      </div>
    );
  }
}

Slider.defaultProps = {
  initialSlide: 0,
  time: 300,
  interval: 1900,
  easing: "ease-out",
  vertical: false,
  reverseDirection: false,
  keyboardNav: false,
};

export default Slider;
