import { Component } from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";
import TheIcon from "../img/TheIcon";

const MenuItem = (item) => {
  const { level, linkTo, title, children } = item;

  const hasChildren = children.length > 0;

  var the_icon = title;
  the_icon = the_icon.replace(/\s+/g, "-").toLowerCase();

  return (
    <li
      key={the_icon}
      className={classnames(
        "menu-item",
        `menu-item--level-${level}`,
        `${the_icon}`,
        {
          "menu-item--has-children": hasChildren,
        }
      )}
    >
      {!linkTo ? (
        <span className="menu-item__title">
          <TheIcon icon={the_icon} />
          {title}
        </span>
      ) : (
        <Link
          className={classnames("link-fill", "menu-item__link")}
          to={linkTo}
        >
          <TheIcon icon="arrow" />
          {title}
        </Link>
      )}

      {hasChildren && <MenuItems items={children} />}
    </li>
  );
};
const MenuItems = ({ items, slug }) => {
  const level = items[0] && items[0].level;
  slug = slug ? "subheader-" + slug.replace("/", "", slug) : null;

  return (
    <ul className={classnames("menu", `menu--level-${level}`, `${slug}`)}>
      {items.map(MenuItem)}
    </ul>
  );
};

class DropdownMenu extends Component {
  render() {
    const { menu, slug } = this.props;

    const items = menu.nested;

    return <MenuItems items={items} slug={slug} />;
  }
}

export default DropdownMenu;
