/* ----- API Resources -----*/
export const BASE_URL = process.env.REACT_APP_API_BASE_URL;

/* Home Page */
export const HOME_PAGE = BASE_URL + "wp-json/acf/v3/pages?slug[]=home-page";

/* News */
export const NEWS = BASE_URL + "wp-json/wp/v2/news";

/* Case Studies */
export const CASE_STUDIES = BASE_URL + "wp-json/wp/v2/casestudies";

/* Blogs */
export const BLOGS = BASE_URL + "wp-json/wp/v2/blog";

/* Products */
export const PRODUCTS = BASE_URL + "wp-json/acf/v3/pages?slug[]=products";
export const PRODUCT = BASE_URL + "wp-json/wp/v2/product";

/* Solutions */
export const SOLUTIONS = BASE_URL + "wp-json/acf/v3/pages?slug[]=solutions";
export const SOLUTION = BASE_URL + "wp-json/wp/v2/solution";

/* About */
export const ABOUT = BASE_URL + "wp-json/acf/v3/pages?slug[]=about";

/* Legal */
export const LEGAL = BASE_URL + "wp-json/wp/v2/legal";

/* Contact */
export const CONTACT = BASE_URL + "wp-json/acf/v3/pages?slug[]=contact";
