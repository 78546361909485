import { Component } from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";

import Logo from "../../assets/icons/images/Logo.svg";

class MobileHeader extends Component {
  state = { scrolled: false };

  componentDidMount() {
    window.addEventListener("scroll", this.onScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.onScroll);
  }

  onScroll = () => {
    const scrollTop =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;
    // px
    const scrolled = scrollTop >= 5;

    this.setState({ scrolled });
  };

  render() {
    const {
      noLogo,
      menuOpen,
      toggleMenu,
      legalOpen,
      closeLegal,
      isDarkTheme,
      forceTransparent,
    } = this.props;
    const { scrolled } = this.state;

    const headerClass = classNames("mobile-header", {
      "mobile-header--menu-open": menuOpen,
      "mobile-header--legal-open": menuOpen,
      "mobile-header--scrolled": scrolled,
      "mobile-header--dark": isDarkTheme,
      "mobile-header--transparent": forceTransparent,
    });

    const hamburgerClass = classNames(
      "mobile-header__menu-btn hamburger hamburger--collapse",
      {
        "is-active": menuOpen || legalOpen,
      }
    );

    return (
      <header className={headerClass}>
        {noLogo || (
          <div className="mobile-header__logo">
            <Link to="/">
            <img src={Logo} alt={"SBG a Catapult Company"}/>
            </Link>
          </div>
        )}
        <button
          onClick={legalOpen ? closeLegal : toggleMenu}
          className={hamburgerClass}
          type="button"
        >
          <span className="sr-only">Open Menu</span>
          <span className="hamburger-box">
            <span className="hamburger-inner" />
          </span>
        </button>
      </header>
    );
  }
}

export default MobileHeader;
