import { FC } from "react";
import useNews from "../../hooks/news/useNews";
import NewsSingle from "../../templates/NewsSingle";
import Error404 from "../../templates/Error404";

export interface NewsSingleProps {}

const NewsSinglePage: FC<NewsSingleProps> = ({ ...props }) => {
  const { news, loading } = useNews(1, 100);
  const item = news?.find((el) => el.slug === (props as any).match.params.slug);

  if (!item && !loading) {
    return <Error404 {...props} />;
  }

  return <NewsSingle postType="blog" {...item} {...props} />;
};

export default NewsSinglePage;
