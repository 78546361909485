import { useEffect, useState } from "react";
import { homePageService } from "../../services/homePage.service";
import { toHomePage } from "../../helpers/parse-data";

export interface HomePage {
  carousel: [];
  oembed: string;
  videoCoverImage: {};
}

export interface UseHomePage {
  homePage: HomePage | undefined;
  loading: boolean;
  error: any;
}

const useHomePage = (): UseHomePage => {
  const [homePage, setHomePage] = useState<HomePage>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    const getHomePage = async () => {
      setLoading(true);
      setError(false);

      try {
        const response = await homePageService.getHomePage();
        if (response) setHomePage(toHomePage(response));
      } catch (error) {
        setError(error);
      }
      setLoading(false);
    };

    getHomePage();
  }, []);

  return { homePage, loading, error };
};

export default useHomePage;
