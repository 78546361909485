import http from "./http.services";
import * as constants from "../constants/resources.constants";

const getBlogs = async (
  pageNumber: number = 1,
  pageSize = 100,
  search?: string
): Promise<object[]> => {
  const { data: blogs } = await http.get(constants.BLOGS, {
    params: {
      page_number: pageNumber,
      page_size: pageSize,
      search: search,
    },
  });
  return blogs;
};

export const blogsService = { getBlogs };
