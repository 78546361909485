import { FC } from "react";
import useNews from "../../hooks/news/useNews";
import Content from "../../templates/Content";

export interface NewsPageProps {}

const NewsPage: FC<NewsPageProps> = ({ ...props }) => {
  const { news } = useNews(1, 100);
  return <Content data={news} postType="news" {...props} />;
};

export default NewsPage;
