import { useEffect, useState } from "react";
import { productsService } from "../../services/products.service";
import { toProductsPage } from "../../helpers/parse-data";

export interface Products {
  id: string;
  date: string;
  title: string;
  shortTitle: string;
  content: string;
  slug: string;
  type: string;
  youtube_video: string;
  image: {
    url: string;
    sizes: {
      [key: string]: string;
    };
  };
}

export interface UseProducts {
  products: Products[];
  loading: boolean;
  error: any;
}

const useProducts = (): UseProducts => {
  const [products, setProducts] = useState<Products[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    const getProducts = async () => {
      setLoading(true);
      setError(false);

      try {
        const response = await productsService.getProducts();
        if (response) setProducts(toProductsPage(response));
      } catch (error) {
        setError(error);
      }
      setLoading(false);
    };

    getProducts();
  }, []);

  return { products, loading, error };
};

export default useProducts;
