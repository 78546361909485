import { Component } from "react";
import format from "date-fns/format";

import BackgroundImage from "./BackgroundImage";
import PostLink from "./PostLink";

class SearchResult extends Component {
  state = {
    isHovered: false,
  };

  constructor() {
    super();

    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
  }

  onMouseEnter() {
    this.setState({ isHovered: true });
  }

  onMouseLeave() {
    this.setState({ isHovered: false });
  }

  render() {
    const { result, options } = this.props;
    const { isHovered } = this.state;

    if (!result.image) {
      return <div>Image not found</div>;
    }

    return (
      <BackgroundImage
        imageUrl={result.image.sizes.medium}
        thumbnailUrl={result.image.sizes.thumbnail}
        color={!isHovered && "DARK_FADE"}
        zoomed={isHovered}
      >
        <div
          className="search-result"
          onMouseEnter={this.onMouseEnter}
          onMouseLeave={this.onMouseLeave}
        >
          {!options?.noDates && (
            <div className="search-result__date p--sm">
              {format(result.date, "D MMMM YYYY")}
            </div>
          )}
          <PostLink
            {...result}
            className="search-result__link"
            miscProps={{
              onFocus: this.onMouseEnter,
              onBlur: this.onMouseLeave,
            }}
          >
            <h2
              className="search-result__title"
              dangerouslySetInnerHTML={{
                __html: result.shortTitle || result.title,
              }}
            />
          </PostLink>
        </div>
      </BackgroundImage>
    );
  }
}

export default SearchResult;
